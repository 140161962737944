import store from '../../../../../../store';
export default {
  name: 'historyRecord',
  props: ['wfHeaderId', 'eventId', 'menuIdForRfpAward'],
  data() {
    return {
      loader: false,
      history: {
        created_by: null,
        creation_date: null,
        last_updated_by: null,
        last_updated_date: null
      }
    };
  },
  mounted() {
    if (this.eventId) {
      this.getHistory(this.eventId);
    } else {
      this.getEventId();
    }
  },
  methods: {
    getHistory(eventId) {
      const payload = {
        historyType: 'history',
        eventId: eventId,
        record_id: this.wfHeaderId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getHistory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.history = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', this.menuIdForRfpAward ? this.menuIdForRfpAward : store.state.shared.menuId)
        .then(response => {
          if (response.status === 200) {
            this.getHistory(response.data.data);
          }
        });
    }
  }
};
